

.statisticsContainer {
  height: 100px;
  display: flex;
  justify-content: center;
}

.statisticsBox {
  border: 1px solid;
  border-right: none;
  flex: 1 1 0;
}

.statisticsBoxIcon {
  width: 70px;
  float: left;
}

.statisticsBoxIcon .pt-icon {
  padding: 10px;
  border-radius: 20px;
  border: 1px solid;
  margin: 30px 15px;
}

.statisticsBoxData {
  float: left;
  width: 168px;
}

.statisticsNumber {
  font-size: 1.6rem;
  margin-top: 16px;
}

.statisticsCaption {
  color: #bbb;
}

.statisticsBox:nth-child(3) .statisticsNumber {
  font-size: 0.9rem;
  margin-top: 28px;
}

.statisticsBox:nth-child(3) .statisticsCaption {
  font-size: 0.8rem;
}

.statisticsBox:nth-child(4) .statisticsNumber {
  font-size: 0.9rem;
  margin-top: 28px;
}

.statisticsBox:nth-child(4) .statisticsCaption {
  font-size: 0.75rem;
}

.statisticsBox:nth-child(5) .statisticsNumber {
  font-size: 0.9rem;
  margin-top: 28px;
}

.statisticsBox:nth-child(5) .statisticsCaption {
  font-size: 0.75rem;
}

.statisticsBox:nth-child(5) {
  border: 1px solid;
}