@import '~normalize.css/normalize.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/table/lib/css/table.css';

body {
  background: #293742;
  outline: none;
}

select {
  outline: none;
}

/**********************
*****   Nav Bar   *****
**********************/

.navbar-link {
  text-decoration: none;
  color: #f5f8fa !important;
}

.navbar-link:hover {
  text-decoration: none !important;
}

/*****************************
*****   Evolution View   *****
*****************************/

.evolutionView {
  width: 1200px;
  margin: 30px auto;
}

/*************************
*****   Admin View   *****
*************************/

.adminView form {
  width: 500px;
  margin: 2rem auto;
}

.adminView .form-header p {
  font-size: 1.3rem;
  font-weight: 300;
  margin-bottom: 15px;
  text-align: center;
}

.adminView .form-top input {
  width: 100%;
}

.adminView .form-columns {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.adminView .form-columns section {
  width: 50%;
}

.adminView .form-left {
  padding-right: 3%;
}

.adminView .form-right {
  padding-left: 3%;
}

.adminView .form-right label {
  margin-bottom: 5px;
}

.adminView .form-right input {
  margin-bottom: 15px;
}

.adminView .form-bottom {
  clear: both;
  display: flex;
  justify-content: left;
}

.adminView .form-bottom section {
  margin-right: 6%;
}

.adminView .form-bottom .pt-checkbox {
  font-size: 0.8rem;
}

.adminView .form-left input {
  width: 100%;
}

.adminView .form-right input {
  width: 50%;
}

.adminView .form-submit {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
}

.adminView .form-submit a,
.adminView .form-submit button {
  width: 160px;
}

.adminView #searchTerm {
  height: 20px;
  width: 120px;
  margin-left: 30px;
  border-radius: 10px;
  font-size: 0.7rem;
}

table.vehicleList {
  width: 1360px;
  margin: 20px auto 30px;
  border-collapse: collapse;
}

table.vehicleList tr:hover {
  background: #3a4f5f;
}

table.vehicleList tr th {
  background: #30404d;
  color: #f6f8fa;
  font-weight: normal;
  font-size: 0.9rem;
  border: 1px solid #1f2b32;
  padding: 5px;
}

table.vehicleList tr th:nth-child(4) {
  width: 112px;
}

table.vehicleList tr th:nth-child(6) {
  width: 82px;
}

table.vehicleList tr th:nth-child(7) {
  width: 68px;
}

table.vehicleList tr th:nth-child(11) {
  width: 100px;
}

table.vehicleList tr td {
  color: #f6f8fa;
  font-size: 0.7rem;
  border: 1px solid #1f2b32;
  padding: 3px 6px 0;
}

table.vehicleList tr td.number {
  color: #bfccd6;
  text-align: center;
}

table.vehicleList tr td.delete {
  color: #bfccd6;
  text-align: center;
  cursor: pointer;
}

table.vehicleList a {
  color: #f5f8fa;
}
table.vehicleList a:hover {
  color: #f5f8fa;
}

table.vehicleList .editVehicle {
  float: right;
  margin: 0 10px 0 0;
  padding: 2px 15px;
  border-radius: 10px;
}

table.vehicleList .editVehicle:hover {
  background: #5c7080;
  cursor: pointer;
}

table.vehicleList .deleteVehicle {
  padding: 5px 9px 0;
  cursor: pointer;
}

table.vehicleList .trans p,
table.vehicleList .fuel p,
table.vehicleList .bodyType p {
  margin: 0;
}


/*********************
*****   Footer   *****
*********************/

.App footer {
  color: #ccc;
  margin: 10px;
  font-size: 0.7rem;
  font-style: italic;
}