.homeView {
  max-width: 1200px;
  margin: 30px auto;
}

.vehicleSelection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

#numberOfVehicles {
  margin: 0 10px 0 5px;
}

.linkAndLastUpdate {
  display: flex;
  justify-content: space-between;
  width: 240px;
  margin-left: 30px;
  margin-top: 3px;
  font-size: 0.8rem;
}

.linkAndLastUpdate p {
  margin: 0;
}


.chartsView {
  clear: both;
  margin-top: 10px;
  /* width: 1200px; */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}